import styled from 'styled-components';

import { colors, transition } from 'theme/theme';

export {
  LargeRadioButtonInput as LargeCheckboxInput,
  LargeRadioButtonContainer as LargeCheckboxContainer,
} from 'components/Radio/LargeRadioButton.styled';

export const CheckmarkHolder = styled.div<{ $checked?: boolean }>`
  background: ${({ $checked }) => ($checked ? colors.brandBlue : colors.white)};
  color: ${({ $checked }) => ($checked ? 'white' : colors.lightContentGrey)};
  box-shadow: inset 0 0 0 1px
    ${({ $checked }) => ($checked ? colors.brandBlue : colors.lightContentGrey)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  transition: all ${transition};
`;
