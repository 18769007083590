import { Component } from 'react';

import { configValue } from 'config/appConfigUtils';
import { recaptcha } from 'utils/recaptcha';

import { StyledRecaptchaInput } from './RecaptchaInput.styled';

export class RecaptchaInput extends Component<{
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: Array<any>) => any;
  siteKey?: string | null;
}> {
  setupRecaptcha = (el: Element | null | undefined) => {
    if (!el) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recaptcha().then((r: any) => {
      const { siteKey, onChange } = this.props;
      r.render(el, {
        sitekey: siteKey || configValue('google', 'recaptcha', 'siteKey'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: (response: any) => {
          onChange(response);
        },
        'expired-callback': () => {
          // Remove old value because it's no longer valid
          onChange();
        },
      });
    });
  };

  render() {
    return <StyledRecaptchaInput ref={this.setupRecaptcha} />;
  }
}
