import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';
import { imageUrl } from 'utils/filestack';
import { hiDPI } from 'utils/style';

export const CmsSignUpBlockHolder = styled.div<{
  $imageHandle: string | undefined;
  $mimetype: string | undefined;
}>`
  background-size: cover;
  background-position: center;
  ${({ $imageHandle, $mimetype }) =>
    $imageHandle &&
    `
    background-image: url('${imageUrl({
      data: {
        handle: $imageHandle,
        mimetype: $mimetype,
      },
      width: 800,
      height: undefined,
      fit: 'max',
      insecureSVG: false,
    })}');
    ${hiDPI()}: {
      background-image: url('${imageUrl({
        data: { handle: $imageHandle, mimetype: $mimetype },
        width: 1600,
        height: undefined,
        fit: 'max',
        insecureSVG: false,
      })}');
    }
    @media all and (min-width: ${cssBreakpoints.lgUp}) {
      background-image: url('${imageUrl({
        data: { handle: $imageHandle, mimetype: $mimetype },
        width: 1280,
        height: undefined,
        fit: 'max',
        insecureSVG: false,
      })}');
      ${hiDPI()}: {
        background-image: url('${imageUrl({
          data: { handle: $imageHandle, mimetype: $mimetype },
          width: 2560,
          height: undefined,
          fit: 'max',
          insecureSVG: false,
        })}');
      }
    }
  `}
`;
