import { Icon } from 'components/Icon/Icon';
import { Color } from 'theme/theme';

type Props = {
  size: number;
  colorName: Color;
  opacity?: number;
};

// TODO: add back animation
export function AnimatedCheckmark({ size, colorName, opacity }: Props) {
  return (
    <Icon
      name="simple-check"
      size={size}
      colorName={colorName}
      opacity={opacity}
    />
  );
}
