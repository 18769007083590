import { RichTextHeadline } from 'components/Text/RichTextHeadline';

type Props = {
  text: string;
  isPageHeader: boolean;
};

export function CmsSignUpBlockHeadline({ text, isPageHeader }: Props) {
  return (
    <RichTextHeadline
      tag={isPageHeader ? 'h1' : undefined}
      styleAs={isPageHeader ? 'h2' : undefined}
    >
      {text}
    </RichTextHeadline>
  );
}
