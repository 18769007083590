import { CheckboxField } from 'components/Form/CheckboxField';
import { RecaptchaInput } from 'components/RecaptchaInput/RecaptchaInput';
import { configValue } from 'config/appConfigUtils';

import { Field } from './Field/Field';

export function RecaptchaField() {
  return configValue('google', 'recaptcha', 'enabled') ? (
    <Field
      name="gRecaptchaResponse"
      renderInput={({ values, updateValue }) => (
        <RecaptchaInput
          key={values.recaptchaKey}
          onChange={(gRecaptchaResponse) => {
            updateValue(gRecaptchaResponse);
          }}
        />
      )}
    />
  ) : (
    <CheckboxField
      name="gRecaptchaResponse"
      label="I'm not a robot"
      valueToChecked={{ recaptcha_mock: true, nope: false }}
    />
  );
}
