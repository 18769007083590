import invariant from 'invariant';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function insertScript(onLoaded: any) {
  const recaptchaScript = document.createElement('script');
  // @ts-expect-error TS(2339): Property 'recaptchaOnloadCallback' does not exist ... Remove this comment to see the full error message
  window.recaptchaOnloadCallback = onLoaded;
  recaptchaScript.setAttribute(
    'src',
    `//www.google.com/recaptcha/api.js?onload=recaptchaOnloadCallback&` +
      `render=explicit&hl=${CURRENT_LOCALE}`,
  );
  recaptchaScript.setAttribute('async', 'async');
  recaptchaScript.setAttribute('defer', 'defer');
  invariant(document.head, 'Missing document.head');
  document.head.appendChild(recaptchaScript);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let loadingPromise: any;

export function resetRecaptcha() {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  return window && window.grecaptcha && window.grecaptcha.reset();
}

export function recaptcha() {
  if (!loadingPromise) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadingPromise = new Promise<any>((resolve) => {
      insertScript(() => {
        resolve(grecaptcha);
      });
    });
  }

  return loadingPromise;
}
