import styled, { css } from 'styled-components';

import { colors, transition } from 'theme/theme';

export const CheckboxHolder = styled.div<{ $hasError?: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: start;
  min-height: 35px;
  border-radius: 8px;
  ${(props) =>
    props.$hasError &&
    css`
      border: solid 1px ${colors.alertRed};
      box-shadow: 0 0 0 1px ${colors.alertRed};
      padding-right: 15px;
      padding-left: 15px;
    `};

  label {
    padding-right: 12px;
  }

  input + label {
    padding-left: 12px;
  }
`;

export const StyledLabel = styled.label`
  cursor: pointer;
`;

export const StyledCheckbox = styled.input`
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  padding: 2px;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;
  appearance: none;

  &::-ms-check {
    display: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
    `}
`;

export const CheckmarkHolder = styled.div<{
  $checked?: boolean;
  $disabled?: boolean;
}>`
  position: absolute;
  background: ${colors.inputGrey};
  box-shadow: 0 0 0 1px rgb(0 0 0 / 20%) inset;
  ${(props) =>
    props.$disabled &&
    css`
      background: ${colors.selectionGrey};
      box-shadow: 0 0 0 1px rgb(0 0 0 / 0%) inset;
    `}
  ${(props) =>
    props.$checked &&
    css`
      background: ${colors.brandBlue};
      box-shadow: 0 0 0 1px rgb(0 0 0 / 0%) inset;
    `}
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all ${transition};
  border-radius: 2px;
`;
