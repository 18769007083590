import { ReactNode } from 'react';

import { Box } from 'components/Box/Box';
import { AnimatedCheckmark } from 'components/Icon/AnimatedCheckmark';
import { Body } from 'components/Text/Body';
import { legacySpace } from 'theme/theme';

import {
  CheckboxHolder,
  CheckmarkHolder,
  StyledCheckbox,
  StyledLabel,
} from './Checkbox.styled';

type Props = {
  'aria-label'?: string;
  'aria-labelledby'?: string;
  'data-qa-id'?: string;
  id?: string;
  name?: string;
  label?: ReactNode;
  placeholder?: string | null | undefined;
  required?: boolean;
  value?: (boolean | null | undefined) | (string | null | undefined);
  checked: boolean;
  hasError?: boolean;
  onChange: (checked: boolean) => void;
  labelPlacement?: 'left' | 'right';
  disabled?: boolean;
  large?: boolean;
  className?: string;
};

export function Checkbox({
  id,
  name,
  label,
  checked,
  hasError,
  onChange,
  labelPlacement = 'right',
  disabled,
  large,
  className,
  value,
  ...props
}: Props) {
  return (
    <CheckboxHolder $hasError={hasError} className={className}>
      {label && labelPlacement === 'left' ? (
        <StyledLabel htmlFor={id}>
          <Body lineHeight={1.4}>{label}</Body>
        </StyledLabel>
      ) : null}
      <Box
        position="relative"
        width={large ? 24 : 16}
        height={large ? 24 : 16}
        flex="0 0 auto"
        marginTop={`${large ? legacySpace[2] : legacySpace[1]}px`}
      >
        <CheckmarkHolder
          $checked={checked}
          $disabled={disabled}
          className="checkmark-container"
        >
          {checked && <AnimatedCheckmark size={16} colorName="white" />}
        </CheckmarkHolder>
      </Box>

      <StyledCheckbox
        // @ts-expect-error TS(2769): No overload matches this call.
        value={value || 'on'}
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
          onChange(e.target.checked);
        }}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      {label && labelPlacement === 'right' ? (
        <StyledLabel htmlFor={id}>
          <Body lineHeight={1.4}>{label}</Body>
        </StyledLabel>
      ) : null}
    </CheckboxHolder>
  );
}
